import { styled } from '@mui/material'
import { Link } from 'react-router-dom'
import PageComponent from '../../../components/PageComponents/PageComponent'

const ReportsMenuPage = () => {
  const pages = [
    {
      name: 'LLA reports',
      link: '/reports/lla-report',
    },
    {
      name: 'Basic report',
      link: '/reports/basic-report',
    },
    // {
    //   name: 'Upload data',
    //   restricted: true,
    //   link: '/reports/upload',
    // },
    // {
    //   name: 'Delete report data',
    //   restricted: true,
    //   link: '/reports/delete',
    // },
  ]

  return (
    <PageComponent title="Reports page">
      <WrapContainer>
        <SelectorWrap>
          {pages.map((page, i) => (
            <SelectorContainer key={i} to={page.link}>
              {page.name}
            </SelectorContainer>
          ))}
        </SelectorWrap>
      </WrapContainer>
    </PageComponent>
  )
}

const WrapContainer = styled('div')({
  width: '100%',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 30,
})
const SelectorWrap = styled('div')({
  flex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 20,
})
const SelectorContainer = styled(Link)(({ theme }) => ({
  width: 300,
  height: 150,
  backgroundColor: theme.palette.background.paper,
  padding: 20,
  borderRadius: 20,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: 20,
}))

export default ReportsMenuPage
